import { useParams } from 'react-router-dom';
const params = new URLSearchParams(window.location.search);

function handleErrors(response: any) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

const getDiscordAC = async() => {
if (window.location.toString().split('#').length > 1) {
    window.location.replace(window.location.toString().replace('#', '?'))
    return 'callback';
}

let code = params.get('access_token') as string;

if (!code) {
	window.location.href = "https://discord.com/api/oauth2/authorize?client_id=928638363634896997&response_type=token&redirect_uri=https%3A%2F%2Fomegaprot.xyz%2F&scope=identify";
	return '';
}

let optionsx = {
  url: 'https://discordapp.com/api/users/@me',
   headers: {
    'authorization': `Bearer ${code}`
  }
}

const ddata = await fetch("https://discordapp.com/api/users/@me", optionsx)
    .then(handleErrors)
    .then(response => response.json())
    .then(response => {
        return(response);
    }).catch(function(error) {
        return(error);
    });
    return ddata;
}

export default getDiscordAC;
