import React, { FC, useCallback } from 'react';

export const useAddUser = () => {
  const addUser = React.useCallback(
      async (walletid, discordid, signature) => {

fetch('https://omegaprot.xyz/express/submit', {
    method: 'POST',
    mode: 'cors',
    headers:{
      'Access-Control-Allow-Origin':'*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
        'walletid': walletid,
        'discordid': discordid,
        'signature': signature
    })
});
	  })

	  return {
		addUser
	  }
}